import React from "react"
import { HeaderMenuFieldsFragment } from "../../../../../graphql-types"
import LanguageSelector, { RelativeLanguagePaths } from "../header-tool-bar/language-selector/language-selector"
import Accordion from "./accordion/accordion"
import * as styles from "./side-menu.module.scss"

type Props = {
  isOpen: boolean
  closeMenu: any
  headerMenu: HeaderMenuFieldsFragment
  locale: string
  defaultLocale: string
  relativeLanguagePaths: RelativeLanguagePaths
}

const SideMenu: React.FC<Props> = ({ headerMenu, isOpen, closeMenu, locale, defaultLocale, relativeLanguagePaths }) => {
  function buildAccordionButton(mainMenuLabel: string, mainMenuPage, subMenu, externalPageLink?: string) {
    const displayedLinks = subMenu
      .filter(link => link.headerDisplay)
      .filter(link => (link.page && link.page.slug) || link.externalPage)
      .map(link => ({
        label: link.label,
        href: link.externalPage
          ? link.externalPage
          : locale === defaultLocale
            ? `/${link.page?.slug}`
            : `/${locale}/${link.page?.slug}`,
      }))
    const hasSubMenu = displayedLinks.length > 0

    if (!mainMenuLabel && !hasSubMenu) {
      return null
    }

    return (
      <Accordion title={mainMenuLabel}>
        {(mainMenuPage || externalPageLink) && (
          <li>
            <a
              href={
                mainMenuPage
                  ? locale === defaultLocale
                    ? `/${mainMenuPage.slug}`
                    : `/${locale}/${mainMenuPage.slug}`
                  : externalPageLink
              }
            >
              {mainMenuLabel}
            </a>
          </li>
        )}
        {displayedLinks.map((link, index) => {
          return (
            <li>
              <a href={link.href} key={index}>
                {link.label}
              </a>
            </li>
          )
        })}
      </Accordion>
    )
  }

  return (
    <nav className={styles.sideNav} role="navigation">
      <ul className={isOpen ? `${styles.sideMenu} ${styles.active}` : `${styles.sideMenu}`} id={"sideMenuUlTag"}>
        {isOpen &&
          (headerMenu.menuSection || [])
            .filter(menuItem => menuItem?.headerDisplay)
            .map((menuItem, index) =>
              buildAccordionButton(menuItem.label, menuItem.page, menuItem.subMenu, menuItem.externalPage)
            )}
        <div className={styles.languageSelectorContainer}>
          <LanguageSelector locale={locale} relativeLanguagePaths={relativeLanguagePaths} />
        </div>
      </ul>

      <div className={isOpen ? styles.blur : null} onClick={closeMenu} />
    </nav>
  )
}

export default SideMenu
