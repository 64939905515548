import React, { useEffect, useState } from "react"
import * as config from "../../../../config.js"
import {
  CustomFormFieldsFragment,
  CustomFormsConfigurationFieldsFragment,
  DatoCmsDatoCmsFormUserEmailContentStructuredText,
  DatoCmsDatoCmsFormSubsidiaryEmailContentStructuredText,
} from "../../../../graphql-types"
import * as styles from "./custom-form.module.scss"
import * as inputsStyles from "../../../components/layout/inputs.module.scss"
import * as contestStyles from "../contest-page.module.scss"
import { graphql } from "gatsby"
import Input, { ValidationType } from "../../../components/layout/form/input"
import Textarea from "../../../components/layout/form/textarea"
import RadioButton from "../../../components/layout/form/radio-button"
import Checkbox from "../../../components/layout/form/checkbox"
import Select from "../../../components/layout/form/select"
import RichDatoCmsContent from "../../../components/rich-datocms-content/rich-datocms-content"
import FileInput from "../../../components/layout/form/file-input"
import { useForm, FieldValues } from "react-hook-form"
import FormError from "../../../components/layout/form/form-error"
import DateInput from "../../../components/layout/form/date-input"
import { sendEmailV2, importFiles } from "../../../api/notifications"
import { formToText } from "./form-to-text"
import { pushGtmEvent } from "../../../gtm/interaction-event"
import { getFieldName } from "./field-name-checker"
import ReCAPTCHA from "react-google-recaptcha"

type RenderProps = {
  data: CustomFormFieldsFragment
  locale: string
  configuration: CustomFormsConfigurationFieldsFragment
  formCaptchaKey: string
}

type FormData = FieldValues

const CustomForm: React.FC<RenderProps> = ({ data, locale, configuration, formCaptchaKey }) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<FormData>()
  const formId = data.id.replace("DatoCmsForm-", "")
  const [formValues, setFormValues] = useState<Map<string, string | boolean | FileList | Date>>(new Map())
  const [formState, setFormState] = useState<FormState>(FormState.Pending)
  const [firstClick, setFirstClick] = useState(true)
  const [captchaValue, setCaptchaValue] = useState("")
  const [notEighteen, setNotEighteen] = useState(false)
  const [fileName, setFileName] = useState("")
  const releaseDate = new Date("2025-03-03T00:00:00")

  useEffect(() => {
    const getTextWidth = (text: string, inputElement: HTMLElement) => {
      const span = document.createElement("span")
      span.textContent = text
      span.style.visibility = "hidden"
      span.style.font = window.getComputedStyle(inputElement).font
      document.body.appendChild(span)
      const width = span.offsetWidth
      document.body.removeChild(span)
      return width
    }

    const textFields = document.querySelectorAll<HTMLElement>(`.${styles.textField}`)
    textFields.forEach(textField => {
      const placeholderWidth = getTextWidth(textField.getAttribute("placeholder") || "", textField)
      const inputWidth = textField.offsetWidth

      if (placeholderWidth + 20 > inputWidth) {
        textField.style.maxWidth = placeholderWidth + 20 + "px"
      }
    })
  }, [])

  function onChange(fieldId, value) {
    const newValues = new Map(formValues)
    newValues.set(fieldId, value)
    setFormValues(newValues)
    if (errors[fieldId]) {
      clearErrors(fieldId)
    }
  }

  function pushGtmFirstClickEvent() {
    if (firstClick) {
      pushGtmEvent("Form", "Form", "Form 1st click", data.title)
      setFirstClick(false)
    }
  }

  const parseEmailContent = (content: string) => {
    let header = ""
    let footer = ""
    const search = "*Form content*"
    if (content.includes(search)) {
      const [before, after] = content.split(search)
      header = before.trim()
      footer = after.trim()
    } else {
      header = content.trim()
    }
    return { header, footer }
  }

  function onSubmit(formData: FieldValues) {
    const submitForm = () => {
      pushGtmEvent("eventga", "Form", "Form Submit", data.title)
      const userEmailContent = formToText(data.userEmailContent as DatoCmsDatoCmsFormUserEmailContentStructuredText)
      const subsidiaryEmailContent = formToText(
        data.subsidiaryEmailContent as DatoCmsDatoCmsFormSubsidiaryEmailContentStructuredText
      )

      const parsedUserEmailContent = parseEmailContent(userEmailContent)

      const parsedSubsidiaryEmailContent = parseEmailContent(subsidiaryEmailContent)

      const notEmptyEmailFieldIds = data.fieldsList
        .filter(field => field.__typename === "DatoCmsUserEmailField")
        .map(field => field.id)
        .filter(fieldId => !!formData[fieldId])
      const notEmptyFiles = data.fieldsList
        .filter(field => field.__typename === "DatoCmsFileField")
        .map(field => field.id)
        .filter(fieldId => !!formData[fieldId] && !!formData[fieldId][0])
        .map(fieldId => formData[fieldId][0])

      setFormState(FormState.Submitting)

      const filesPromises = notEmptyFiles.map(importFiles)

      Promise.allSettled(filesPromises).then(results => {
        results.forEach((result, index) => {
          if (result.status === "rejected") {
            const fieldId = data.fieldsList.find(
              field => field.__typename === "DatoCmsFileField" && formData[field.id]?.[0] === notEmptyFiles[index]
            )?.id

            if (fieldId) {
              setError(fieldId as keyof FormData, {
                type: "server",
                message: `Error importing file "${notEmptyFiles[index].name}"`,
              })
            }
          }
        })
      })

      const prmColor = config.primary_color
      let payload = {
        trigram: process.env.GATSBY_SUBSIDIARY_TRIGRAM,
        idForm: formId,
        fields: data.fieldsList

          .filter(field => !["DatoCmsSimpleText", "DatoCmsFileField"].includes(field.__typename))
          .map(field => {
            if ("name" in field) {
              if (field.__typename === "DatoCmsCheckboxField") {
                return { key: field.name, value: formData[field.id] ? "✓" : "✖︎" }
              }
              return { key: field.name, value: formData[field.id] }
            }
          }),

        logo: configuration.logoSubsidiary?.url || "",
        // prmColor = {red: 0, green: 0, blue: 0, alpha: 0}, we need to convert it to hex
        primaryColor: `#${prmColor.red.toString(16).padStart(2, "0")}${prmColor.green.toString(16).padStart(2, "0")}${prmColor.blue.toString(16).padStart(2, "0")}`,

        locale: locale || "fr",
        files: notEmptyFiles,
      }

      const subsidiaryEmailPromise = sendEmailV2({
        ...payload,
        header: parsedSubsidiaryEmailContent.header,
        footer: parsedSubsidiaryEmailContent.footer,
      })

      const userEmailPromises = notEmptyEmailFieldIds.map(emailFieldId => {
        sendEmailV2({
          ...payload,
          email: formData[emailFieldId],
          header: parsedUserEmailContent.header,
          footer: parsedUserEmailContent.footer,
        })
      })

      return Promise.all([subsidiaryEmailPromise, ...userEmailPromises]).then(
        () => setFormState(FormState.Success),
        () => setFormState(FormState.Error)
      )
    }

    if (
      !formCaptchaKey ||
      (formCaptchaKey && captchaValue) ||
      (window.location.hostname === "localhost" && process.env.GATSBY_SUBSIDIARY_TRIGRAM.toUpperCase() === "KWP")
    ) {
      submitForm()
    } else {
      console.log("Captcha not valid", process.env.GATSBY_SUBSIDIARY_TRIGRAM)
    }
  }

  const isNotEighteen = (date: Date | undefined) => {
    if (!date) {
      return false
    }
    const now = new Date()
    const eighteenYearsAgo = new Date(now.getFullYear() - 18, now.getMonth(), now.getDate())

    return date > eighteenYearsAgo
  }

  return (
    <form className={styles.customForm} onSubmit={handleSubmit(onSubmit)} noValidate={true}>
      {/* <h3 className={styles.title}>Participer</h3> */}
      {data.fieldsList.map(field => {
        switch (field.__typename) {
          case "DatoCmsSingleLineTextField":
            const validationType = toValidationType(field.format)

            return (
              <div className={inputsStyles.formPart + " " + styles.formPart} key={field.id}>
                {field.format === "Date" ? (
                  <DateInput
                    id={field.id}
                    name={field.id}
                    aria-label={field.name}
                    placeholder={field.placeholder}
                    required={true}
                    control={control}
                    maxDate={new Date()}
                    onFocus={pushGtmFirstClickEvent}
                    onChange={value => {
                      onChange(field.id, value)
                      setNotEighteen(isNotEighteen(value))
                    }}
                    hasErrors={!!errors[field.id]}
                  />
                ) : (
                  <Input
                    id={field.id}
                    name={field.id}
                    aria-label={field.name}
                    type="text"
                    autocomplete={getFieldName(field.name) !== undefined}
                    placeholder={field.placeholder}
                    required={field.mandatory}
                    className={styles.textField}
                    aria-describedby="singleLineErrorDesc"
                    register={register}
                    validationType={validationType}
                    hasErrors={!!errors[field.id]}
                    onFocus={pushGtmFirstClickEvent}
                  />
                )}
                <FormError errors={errors} name={field.id} type="required">
                  {configuration.requiredFieldError}
                </FormError>
                {validationType === ValidationType.PhoneNumber && (
                  <FormError errors={errors} name={field.id} type="pattern" id="singleLineErrorDesc">
                    {configuration.phoneNumberFieldError}
                  </FormError>
                )}
                {validationType === ValidationType.Email && (
                  <FormError errors={errors} name={field.id} type="pattern" id="singleLineErrorDesc">
                    {configuration.emailFieldError}
                  </FormError>
                )}
              </div>
            )

          case "DatoCmsUserEmailField":
            // field.id = getFieldName(field.name) != undefined ? getFieldName(field.name) : field.id
            return (
              <div className={inputsStyles.formPart + " " + styles.formPart} key={field.id}>
                <Input
                  id={field.id}
                  type="text"
                  name={field.id}
                  aria-label={field.name}
                  autocomplete={getFieldName(field.name) !== undefined}
                  placeholder={field.placeholder}
                  required={field.mandatory}
                  className={styles.textField}
                  register={register}
                  validationType={ValidationType.Email}
                  hasErrors={!!errors[field.id]}
                  aria-describedby="emailErrorDesc"
                  onFocus={pushGtmFirstClickEvent}
                />
                <FormError errors={errors} name={field.id} type="required">
                  {configuration.requiredFieldError}
                </FormError>
                <FormError errors={errors} name={field.id} type="pattern" id="emailErrorDesc">
                  {configuration.emailFieldError}
                </FormError>
              </div>
            )

          case "DatoCmsMultiLinesTextField":
            return (
              <div className={inputsStyles.formPart + " " + styles.formPart} key={field.id}>
                {/* <label
                  htmlFor={field.id}
                  className={inputsStyles.label + (field.mandatory ? " " + inputsStyles.labelRequired : "")}
                >
                  {field.name}
                </label> */}
                <Textarea
                  id={field.id}
                  name={field.id}
                  aria-label={field.name}
                  placeholder={field.placeholder}
                  required={field.mandatory}
                  hasErrors={!!errors[field.id]}
                  register={register}
                  onFocus={pushGtmFirstClickEvent}
                />
                <FormError errors={errors} name={field.id} type="required">
                  {configuration.requiredFieldError}
                </FormError>
              </div>
            )

          case "DatoCmsRadioButtonsField":
            return (
              <fieldset className={inputsStyles.formPart + " " + styles.formPart} key={field.id}>
                <legend className={inputsStyles.label + (field.mandatory ? " " + inputsStyles.labelRequired : "")}>
                  {field.name}
                </legend>
                <div className={inputsStyles.radioButtons}>
                  {(JSON.parse(field.options) as string[]).map((option, index) => (
                    <RadioButton
                      key={index}
                      id={field.id + index}
                      label={option}
                      name={field.id}
                      required={field.mandatory}
                      value={option}
                      hasErrors={!!errors[field.id]}
                      register={register}
                      onFocus={pushGtmFirstClickEvent}
                    />
                  ))}
                </div>
                <FormError errors={errors} name={field.id} type="required">
                  {configuration.requiredFieldError}
                </FormError>
              </fieldset>
            )

          case "DatoCmsCheckboxField":
            return (
              <div className={inputsStyles.formPart + " " + styles.formPart} key={field.id}>
                <Checkbox
                  key={field.id}
                  id={field.id}
                  label={field.name}
                  name={field.id}
                  required={field.mandatory}
                  hasErrors={!!errors[field.id]}
                  register={register}
                  onFocus={pushGtmFirstClickEvent}
                />
                <FormError errors={errors} name={field.id} type="required">
                  {configuration.requiredFieldError}
                </FormError>
              </div>
            )

          case "DatoCmsFileField":
            return (
              <div className={inputsStyles.formPart + " " + styles.formPart} key={field.id}>
                <label
                  htmlFor={field.id}
                  className={inputsStyles.label + (field.mandatory ? " " + inputsStyles.labelRequired : "")}
                >
                  {field.name}
                </label>
                <FileInput
                  key={field.id}
                  id={field.id}
                  type="file"
                  name={field.id}
                  placeholder={field.placeholder}
                  required={field.mandatory}
                  onChange={value => onChange(field.id, value)}
                  className={styles.fileField}
                  register={register}
                  onFocus={pushGtmFirstClickEvent}
                  accept=".jpg,.jpeg,.png,.gif,.doc,.docx,.pdf,.xls,.xlsx"
                />
                <FormError errors={errors} name={field.id} type="required">
                  {configuration.requiredFieldError}
                </FormError>
                <FormError errors={errors} name={field.id} type="server">
                  {errors[field.id]?.message}
                </FormError>
              </div>
            )

          case "DatoCmsSelectField":
            return (
              <div className={inputsStyles.formPart + " " + styles.formPart} key={field.id}>
                <label
                  htmlFor={field.id}
                  className={inputsStyles.label + (field.mandatory ? " " + inputsStyles.labelRequired : "")}
                >
                  {field.name}
                </label>
                <Select
                  key={field.id}
                  id={field.id}
                  name={field.id}
                  autocomplete={getFieldName(field.name) !== undefined}
                  options={JSON.parse(field.options)}
                  required={field.mandatory}
                  className={styles.select}
                  hasErrors={!!errors[field.id]}
                  register={register}
                  onFocus={pushGtmFirstClickEvent}
                />
                <FormError errors={errors} name={field.id} type="required">
                  {configuration.requiredFieldError}
                </FormError>
              </div>
            )

          case "DatoCmsSimpleText":
            return <RichDatoCmsContent key={field.id} data={[field]} />
        }
      })}
      {notEighteen && (
        <>
          <label
            htmlFor="autorisation parentale"
            className={contestStyles.button + " " + inputsStyles.labelRequired + " " + styles.autorisationLabel}
            role="button"
            tabIndex="0"
          >
            Transférer l'autorisation
          </label>

          <FileInput
            type="file"
            id="autorisation parentale"
            name="autorisation parentale"
            placeholder="autorisation parentale"
            onChange={value => {
              onChange("autorisation parentale", value)
              setFileName(value[0]?.name)
            }}
            required={true}
            className={styles.fileField}
            register={register}
            onFocus={pushGtmFirstClickEvent}
            accept=".jpg,.jpeg,.png,.gif,.doc,.docx,.pdf"
          />
          <FormError errors={errors} name={"autorisation parentale"} type="required">
            {"autorisation parentale obligatoire pour les mineurs"}
          </FormError>
          <FormError errors={errors} name={"autorisation parentale"} type="server">
            {"Erreur lors de l'import de l'autorisation parentale"}
          </FormError>
          <div className={styles.fileName}>{fileName}</div>
        </>
      )}

      {formCaptchaKey && (
        <div className={styles.captcha}>
          <ReCAPTCHA sitekey={formCaptchaKey} onChange={onChangeCaptcha} />
        </div>
      )}

      {new Date() < releaseDate && (
        <div className={styles.releaseDate}>
          <p>Les inscriptions via le formulaire seront ouvertes à partir du 3 mars 2025 : </p>
        </div>
      )}
      <button
        type="submit"
        className={contestStyles.button + " " + styles.submitButton}
        disabled={formState === FormState.Submitting || formState === FormState.Success || new Date() < releaseDate}
      >
        Je m'inscris
      </button>
      {formState === FormState.Error && (
        <div className={inputsStyles.formError + " " + styles.globalError}>{data.formError}</div>
      )}
      {formState === FormState.Success && <div className={styles.successMessage}>{data.successMessage}</div>}
    </form>
  )

  function onChangeCaptcha(value) {
    setCaptchaValue(value)
  }
}

export default CustomForm

export const fragments = graphql`
  fragment CustomFormFields on DatoCmsForm {
    id
    title
    submitButtonLabel
    from
    # receivers {
    #   ...EmailReceiversFields
    # }
    successMessage
    formError
    subsidiaryEmailSubject
    subsidiaryEmailContent {
      value
      blocks
      links {
        id: originalId
        fragmentType
      }
    }
    userEmailSubject
    userEmailContent {
      value
      blocks
      links {
        id: originalId
        fragmentType
      }
    }
    fieldsList {
      __typename
      ... on DatoCmsSingleLineTextField {
        id
        name
        placeholder
        mandatory
        format
      }
      ... on DatoCmsMultiLinesTextField {
        id
        name
        placeholder
        mandatory
      }
      ... on DatoCmsRadioButtonsField {
        id
        name
        mandatory
        options
      }
      ... on DatoCmsCheckboxField {
        id
        name
        mandatory
      }
      ... on DatoCmsFileField {
        id
        name
        mandatory
        placeholder
      }
      ... on DatoCmsSelectField {
        id
        name
        mandatory
        options
      }
      ... on DatoCmsUserEmailField {
        id
        name
        placeholder
        mandatory
      }
      ... on DatoCmsSimpleText {
        ...SimpleTextFields
      }
    }
  }

  fragment EmailReceiversFields on DatoCmsEmailReceiversBlock {
    email
    sendType
  }

  fragment CustomFormsConfigurationFields on DatoCmsFormsConfiguration {
    requiredFieldError
    phoneNumberFieldError
    emailFieldError
    logoSubsidiary {
      alt
      url
    }
  }
`

function toValidationType(format: string) {
  switch (format) {
    case "Email":
      return ValidationType.Email
    case "Phone number":
      return ValidationType.PhoneNumber
    default:
      return ValidationType.None
  }
}

enum FormState {
  Pending,
  Submitting,
  Success,
  Error,
}
