import React, { useRef } from "react"
import { graphql } from "gatsby"

import header from "../../../static/images/auch/header.png"
import form from "../../../static/images/auch/form.png"
import defi from "../../../static/images/auch/defi.png"
import rewards from "../../../static/images/auch/rewards.jpg"
// partners images
import region from "../../../static/images/auch/partners/region.png"
import alliance from "../../../static/images/auch/partners/alliance.png"
import auch from "../../../static/images/auch/partners/auch.png"
import ca from "../../../static/images/auch/partners/CA.svg"
import cci from "../../../static/images/auch/partners/CCI.png"
import decathlon from "../../../static/images/auch/partners/decathlon.svg"
import inter from "../../../static/images/auch/partners/inter.png"

import * as styles from "../type-page/type-page.module.scss"
import * as contestStyles from "./contest-page.module.scss"
import { useFormatLocale } from "../../hooks"
import Layout from "../../components/layout/layout"
import TitleBanner from "../../components/type-page/title-banner/title-banner"
import Breadcrumb from "../../components/type-page/breadcrumb/breadcrumb"

import { RelativeLanguagePaths } from "../../components/layout/header/header-tool-bar/language-selector/language-selector"
import LoadScripts from "../../components/load-scripts"
import { ContestPageQuery } from "../../../graphql-types"

import Countdown from "../../components/countdown/countdown"
import CustomForm from "./custom-form/custom-form"

type RenderProps = {
  data: ContestPageQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    relativeLanguagePaths: RelativeLanguagePaths
  }
}

const ContestPage: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const locale = useFormatLocale(pageContext.locale)
  const defaultLocale = useFormatLocale(pageContext.defaultLocale)
  const endDate = new Date("2025-05-01T23:59:59")
  const today = new Date()

  const formRef = useRef<HTMLDivElement>(null)

  return (
    <Layout
      location={location}
      menuData={data.datoCmsMenu}
      footerData={data.datoCmsFooter}
      toolbarData={data.datoCmsGlobalConfiguration}
      scriptsData={data.datoCmsGlobalConfiguration}
      locale={locale}
      defaultLocale={defaultLocale}
      relativeLanguagePaths={pageContext.relativeLanguagePaths}
      mySubscriptionsPage={data.datoCmsMySubscriptionsPage}
    >
      <div className={styles.typePage}>
        <TitleBanner title={"Title Banner"} />
        <Breadcrumb
          menuData={data.datoCmsMenu}
          pageTitle={"Title Breadcrumb"}
          locale={locale}
          defaultLocale={defaultLocale}
        />
        <div className={contestStyles.typePageContent}>
          <div className={contestStyles.content}>
            {/* Header */}
            <header className={contestStyles.fullImage}>
              <img src={header} alt="header" />

              <h2 className={contestStyles.title}>
                <p className={contestStyles.t1}>LE PRIX DE LA </p>
                <p className={contestStyles.t2}>MOBILITÉ</p>
              </h2>
            </header>
            {/* Chapo section */}
            <section className={`${contestStyles.section} ${contestStyles.chapo}`}>
              <h3 className={contestStyles.subtitle}>Tu es acteur du changement ? </h3>
              <p className={`${contestStyles.chapoText} ${contestStyles.subtitle}`}>
                François et Bélina veulent se rendre au Château de Lavardens. Conçois leur la ligne la plus efficace,
                écoresponsable et accessible à tous.
              </p>

              <button
                className={`${contestStyles.button}`}
                onClick={() => {
                  formRef.current?.scrollIntoView({ behavior: "smooth" })
                }}
              >
                Je me lance
              </button>
            </section>
            {/* Presentation Section */}
            <section className={`${contestStyles.section} ${contestStyles.presentation}`}>
              <h3 className={contestStyles.subtitle}>Présentation du concours</h3>
              <iframe
                className={contestStyles.presentationVideo}
                src="https://www.youtube.com/embed/wrZJBcYtQpQ"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </section>
            {/* Defi Section */}
            <section className={`${contestStyles.section} ${contestStyles.defi}`}>
              <div>
                <h3 className={contestStyles.subtitle}>Le défi</h3>
                <p className={`${contestStyles.text}`}>
                  Vous avez entre 16 et 25 ans et vous vivez, ou étudiez, ou travaillez, dans l'agglomération du Grand
                  Auch, prenez les rênes de l'innovation !
                </p>
                <p className={`${contestStyles.text}`}>
                  Réalisez un pitch vidéo présentant votre idée de ligne Auch-Laverdens la plus efficace, écoresponsable
                  et accessible à tous en développant le ou les moyens de mobilité disponibles sur le territoire (bus,
                  piste piétonne/cyclable, vélo…).
                </p>
                <p className={`${contestStyles.text}`}>
                  Dans votre pitch de 2 minutes, présentez votre itinéraire, le temps de parcours et le ou les moyens de
                  mobilité choisi (s). Puis, expliquez pourquoi votre solution est la plus efficace, écoresponsable et
                  accessible à tous.
                </p>
              </div>
              <img src={defi} alt="defi" className={`${contestStyles.mediumImage}`} />
            </section>
            {/* Pitch section */}
            <section className={`${contestStyles.section} ${contestStyles.pitch}`}>
              {/* <img src={pitch} alt="pitch" className={`${contestStyles.mediumImage}`} /> */}

              <div>
                <h3 className={contestStyles.subtitle}>Comment réaliser une vidéo pitch ?</h3>
                <p className={`${contestStyles.text}`}>
                  Le pitch vidéo est une courte vidéo qui présente un projet de manière dynamique. Pour en savoir plus
                  et obtenir des conseils, visionnez la vidéo !
                </p>
                <iframe
                  className={contestStyles.presentationVideo}
                  src="https://www.youtube.com/embed/NbGsPwMg4Ps"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <h3 className={contestStyles.subtitle}>Comment télécharger et nous envoyer votre vidéo ?</h3>
                {/* Afin de réaliser la video via l'application snaptchat, merci de suivre les étapes suivantes : 
                  Ouvrir votre application snapchat sur votre smartphone
                  Enregistrer votre vidéo 
                  cliquer sur le bouton enregistrer qui se trouve en bas à gauche de votre écran (symbole Flèche)
                  Cliquer sur le bouton copier le lien qui se trouve à la même place en bas à gauche de votre écran (symbole lien)
                  Coller le lien de votre vidéo dans le champs dédié disponible sur votre demande */}
                <p className={`${contestStyles.text}`}>
                  <ol className={contestStyles.text}>
                    <li>
                      Ouvrir votre application Snapchat sur votre smartphone. Si vous ne l'avez pas encore, vous pouvez
                      la{" "}
                      <a href="https://www.snapchat.com/download" target="_blank" rel="noopener noreferrer">
                        télécharger ici
                      </a>
                      {". "}
                    </li>
                    <li>Enregistrer votre vidéo</li>
                    <li>
                      Cliquer sur le bouton enregistrer qui se trouve en bas à gauche de votre écran (symbole Flèche)
                    </li>
                    <li>
                      Cliquer sur le bouton copier le lien qui se trouve à la même place en bas à gauche de votre écran
                      🔗 (symbole lien)
                    </li>
                    <li>Coller le lien de votre vidéo dans le champ dédié disponible sur votre demande</li>
                  </ol>
                </p>
                <h3 className={contestStyles.subtitle}>Comment participer?</h3>
                <p className={`${contestStyles.text}`}>
                  Compléter le formulaire et si vous êtes mineur, l'autorisation parentale, puis envoyez votre vidéo au
                  plus tard le 1er mai 2025.
                </p>
              </div>
            </section>

            {/* Countdown */}
            <section className={`${contestStyles.section} ${contestStyles.countDown}`}>
              <p className={`${contestStyles.subtitle} `}>
                <Countdown targetDate={endDate.toISOString()} timeIsUpMessage="Les inscriptions sont cloturées" />
              </p>
              <button
                className={`${contestStyles.button}`}
                onClick={() => {
                  formRef.current?.scrollIntoView({ behavior: "smooth" })
                }}
              >
                Je me lance
              </button>
            </section>
            {/* Rewards section */}
            <section className={`${contestStyles.section} ${contestStyles.rewards}`}>
              <div></div>
              <div className={contestStyles.rewardsImageContainer}>
                <img
                  src={rewards}
                  alt="rewards"
                  className={`${contestStyles.mediumImage} ${contestStyles.rewardsImg}`}
                />
                <h3 className={contestStyles.subtitle}>Des récompenses incroyables à gagner!</h3>
                <div className={contestStyles.rewardsOpacifyingFilter} />
              </div>
              <p className={`${contestStyles.text}`}>
                Rendez-vous à Auch, le 11 juin pour la cérémonie. A gagner, une trottinette électrique et bien d'autres
                lots.
              </p>
            </section>
            {/* Form */}

            <div ref={formRef} className={` ${contestStyles.form}`}>
              <p className={`${contestStyles.subtitle}`}>Participer</p>
              <p className={`${contestStyles.text}`}>Si vous êtes mineur</p>
              <button
                className={`${contestStyles.button}`}
                onClick={() => {
                  // download the parental authorization from public folder
                  window.open("/images/auch/autorisation.pdf", "_blank")
                }}
              >
                Télécharger l'autorisation parentale
              </button>
              {today < endDate ? (
                <CustomForm
                  key={data.datoCmsForm.id}
                  data={data.datoCmsForm}
                  locale={locale}
                  configuration={data.datoCmsFormsConfiguration}
                  formCaptchaKey={data.datoCmsGlobalConfiguration.formCaptchaKey}
                />
              ) : (
                <div> Les inscriptions sont cloturées</div>
              )}
            </div>

            {/* Participate Section */}
            <div className={`${contestStyles.fullImage} ${contestStyles.participate}`}>
              <img src={form} alt="participez" />
              <div className={contestStyles.participateContent}>
                <p>N’attendez plus, </p>
                <p>participez dès </p>
                <p>maintenant!</p>
                <button
                  className={`${contestStyles.button} `}
                  onClick={() => {
                    formRef.current?.scrollIntoView({ behavior: "smooth" })
                  }}
                >
                  Je me lance
                </button>
              </div>
            </div>
            {/* Partners */}
            <section className={`${contestStyles.section} ${contestStyles.partners}`}>
              <p className={`${contestStyles.subtitle}`}>Merci à nos partenaires</p>
              <div className={contestStyles.partnersLogos}>
                <img src={region} alt="Région Occitanie" />
                <img src={ca} alt="Crédit Agricol" />

                <img src={decathlon} alt="Décathlon" />
                <img src={auch} alt="Grand Auch" />
                <img src={alliance} alt="Alliance" />

                <img src={inter} alt="Inter Sport" />
                <img src={cci} alt="CCI GERS" />
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ data }: { data: ContestPageQuery }) => {
  return <LoadScripts scriptData={data.datoCmsGlobalConfiguration} />
}

export const pageQuery = graphql`
  query contestPage($locale: String!) {
    site {
      siteMetadata {
        title
      }
    }
    datoCmsMySubscriptionsPage(locale: $locale) {
      ...MySubscriptionsPageFields
    }
    datoCmsMenu(locale: $locale) {
      ...HeaderMenuFields
      ...FooterMenuFields
      label
      label2
      label3
      label4
      column1 {
        page {
          slug
          content {
            ... on DatoCmsInfoTrafficModule {
              id
              model {
                name
              }
            }
          }
        }
      }
      column2 {
        page {
          slug
          content {
            ... on DatoCmsInfoTrafficModule {
              id
              model {
                name
              }
            }
          }
        }
      }
      column3 {
        page {
          slug
          content {
            ... on DatoCmsInfoTrafficModule {
              id
              model {
                name
              }
            }
          }
        }
      }
      column4 {
        page {
          slug
          content {
            ... on DatoCmsInfoTrafficModule {
              id
              model {
                name
              }
            }
          }
        }
      }
    }

    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }

    datoCmsGlobalConfiguration {
      id
      twitterDisplay
      fbDisplay
      sideBlocks {
        ...SideBlockFields
      }
      ...AllNewsModuleConfigurationFields
      ...HeaderToolBarFields
      ...ScriptsFields
      formCaptchaKey
    }
    datoCmsFormsConfiguration {
      ...CustomFormsConfigurationFields
    }
    datoCmsForm(title: { eq: "Jeu concours" }) {
      ...CustomFormFields
    }
  }
`

export default ContestPage
