import React, { useState, useEffect } from "react"

interface CountdownProps {
  targetDate: string
  timeIsUpMessage?: string
}

interface TimeLeft {
  days: number
  hours: number
  minutes: number
  seconds: number
}

const Countdown: React.FC<CountdownProps> = ({ targetDate, timeIsUpMessage = "Le temps est écoulé!" }) => {
  const calculateTimeLeft = (): TimeLeft | null => {
    const difference = new Date(targetDate).getTime() - new Date().getTime()

    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return null
  }

  const [timeLeft, setTimeLeft] = useState<TimeLeft | null>(calculateTimeLeft())

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearInterval(timer)
  }, [targetDate])

  if (!timeLeft) {
    return <p>{timeIsUpMessage}</p>
  }

  return (
    <p>
      {timeLeft.days} jours, {timeLeft.hours} heures, {timeLeft.minutes} minutes, {timeLeft.seconds} s
    </p>
  )
}

export default Countdown
