// extracted by mini-css-extract-plugin
export var button = "contest-page-module--button--b0302";
export var chapo = "contest-page-module--chapo--2e7ee";
export var chapoText = "contest-page-module--chapoText--d485c";
export var content = "contest-page-module--content--db6f8";
export var countDown = "contest-page-module--countDown--4e552";
export var defi = "contest-page-module--defi--75a49";
export var form = "contest-page-module--form--19769";
export var fullImage = "contest-page-module--fullImage--b0c40";
export var mediumImage = "contest-page-module--mediumImage--bf6f1";
export var participate = "contest-page-module--participate--37439";
export var participateContent = "contest-page-module--participateContent--2f012";
export var partners = "contest-page-module--partners--0314b";
export var partnersLogos = "contest-page-module--partnersLogos--acafb";
export var pitch = "contest-page-module--pitch--2d6f0";
export var presentation = "contest-page-module--presentation--ad888";
export var presentationVideo = "contest-page-module--presentationVideo--49eae";
export var reactDatepickerWrapper = "contest-page-module--react-datepicker-wrapper--2cc03";
export var rewards = "contest-page-module--rewards--16cfc";
export var rewardsImageContainer = "contest-page-module--rewardsImageContainer--61225";
export var rewardsImg = "contest-page-module--rewardsImg--5ccba";
export var rewardsOpacifyingFilter = "contest-page-module--rewardsOpacifyingFilter--5ccab";
export var section = "contest-page-module--section--a4fb5";
export var subtitle = "contest-page-module--subtitle--7640e";
export var t1 = "contest-page-module--t1--f43dd";
export var t2 = "contest-page-module--t2--92cb0";
export var text = "contest-page-module--text--8690e";
export var title = "contest-page-module--title--2ef54";
export var typePageContent = "contest-page-module--typePageContent--e6cf6";