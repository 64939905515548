import React, { useEffect, useRef, useState } from "react"
import ReactCountryFlag from "react-country-flag"
import * as styles from "./language-selector.module.scss"
import * as config from "../../../../../../config"
import { arrowBackIcon } from "../../../../../../static/images"

export type RelativeLanguagePaths = {
  locale: string
  value: string
}[]

type RenderProps = {
  locale: string
  relativeLanguagePaths?: RelativeLanguagePaths
}

const LanguageSelector: React.FC<RenderProps> = ({ locale, relativeLanguagePaths }) => {
  const [isOpen, setIsOpen] = useState(false)
  const menuButtonRef = useRef(null)
  const menuContainerRef = useRef(null)

  const mainLocale = config.locales[0].toLowerCase().split("-").reverse().join("-")
  const otherLocales = config.locales
    .map(otherLocale => otherLocale.toLowerCase().split("-").reverse().join("-"))
    .filter(locales => locales !== locale)

  const focusMenu = () => menuButtonRef?.current?.focus()
  const focusFirstMenuItem = () => {
    if (typeof window === "undefined") return
    const firstMenuItem = document.getElementsByClassName("jsMenuItem")[0] as HTMLElement
    if (!firstMenuItem) {
      return
    }
    firstMenuItem.focus()
  }
  const openMenu = () => setIsOpen(true)
  const closeMenu = () => setIsOpen(false)
  const hasDropdown = otherLocales.length > 0

  // When the menu lose focus, we need to close it
  useEffect(() => {
    if (!isOpen || typeof window === "undefined") {
      return
    }
    const onFocus = event => {
      if (!event.target.classList || !event.target.classList.contains("jsMenuFocusable")) {
        closeMenu()
        window.removeEventListener("focus", onFocus, true)
      }
    }
    window.addEventListener("focus", onFocus, true)
    return () => window.removeEventListener("focus", onFocus, true)
  }, [isOpen])

  const closeMenuOnEscape = <T extends React.KeyboardEvent>(event: T) => {
    if (event.key === "Escape") {
      event.preventDefault()
      focusMenu()
      closeMenu()
    }
  }

  const getLocaleLink = (currentLocale: string) => {
    const relativeLocaleLink = relativeLanguagePaths?.find(relativeLink => relativeLink.locale === currentLocale)
    if (currentLocale === mainLocale) {
      return `/${relativeLocaleLink ? relativeLocaleLink.value : ""}`
    }
    return `/${currentLocale}/${relativeLocaleLink ? relativeLocaleLink.value : ""}`
  }

  const getLocaleFlag = (localeFlag: string) => {
    switch (localeFlag) {
      case "en":
      case "gb-en":
        return "gb"
      case "us-en":
        return "us"
      case "eu-en":
        return "eu"
      case "sn-fr":
        return "sn"
      default:
        return localeFlag
    }
  }

  if (!hasDropdown) {
    return (
      <div className={styles.languageSelectorMenu}>
        <div id="languageSelectorMenu" className={styles.menuButton}>
          <ReactCountryFlag
            className={styles.countryFlag}
            countryCode={getLocaleFlag(locale)}
            title={locale}
            style={{
              width: "29px",
              height: "21px",
            }}
            svg
          />
          <span className={styles.countryLabel}>{locale}</span>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.languageSelectorMenu}>
      <button
        id="languageSelectorMenu"
        className={styles.menuButton + " jsMenuFocusable"}
        aria-haspopup={hasDropdown}
        aria-controls="flagItems"
        aria-expanded={isOpen && hasDropdown}
        onClick={() => {
          openMenu()
          focusFirstMenuItem()
        }}
        title={config["language_selector_title"][config.locale]}
        onKeyDown={closeMenuOnEscape}
        ref={menuButtonRef}
        disabled={!hasDropdown}
      >
        <ReactCountryFlag
          className={styles.countryFlag}
          countryCode={getLocaleFlag(locale)}
          title={locale}
          style={{
            width: "29px",
            height: "21px",
          }}
          svg
        />
        <span className={styles.countryLabel}>{locale}</span>
        <img src={arrowBackIcon} className={styles.arrowIcon} alt="" aria-hidden="true" />
      </button>
      <div className={isOpen ? `${styles.dropdownContent} ${styles.active}` : styles.dropdownContent}>
        <ul id="flagItems" aria-labelledby="languageSelectorMenu" ref={menuContainerRef} onKeyDown={closeMenuOnEscape}>
          {otherLocales.map((otherLocale, index) => {
            return (
              <li key={index}>
                <a
                  className={styles.subMenuButton + " jsMenuFocusable jsMenuItem"}
                  href={getLocaleLink(otherLocale)}
                  onKeyDown={closeMenuOnEscape}
                >
                  <ReactCountryFlag
                    className={styles.countryFlag}
                    countryCode={getLocaleFlag(otherLocale)}
                    title={otherLocale}
                    style={{
                      width: "29px",
                      height: "21px",
                    }}
                    svg
                  />
                  <span className={styles.countryLabel}>{otherLocale}</span>
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default LanguageSelector
