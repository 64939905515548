// extracted by mini-css-extract-plugin
export var autorisationLabel = "custom-form-module--autorisationLabel--76557";
export var captcha = "custom-form-module--captcha--5a119";
export var customForm = "custom-form-module--customForm--6b7b0";
export var fileField = "custom-form-module--fileField--50ce0";
export var fileName = "custom-form-module--fileName--4e158";
export var formPart = "custom-form-module--formPart--f8494";
export var globalError = "custom-form-module--globalError--a86d8";
export var releaseDate = "custom-form-module--releaseDate--5017f";
export var select = "custom-form-module--select--d25ed";
export var submitButton = "custom-form-module--submitButton--c6b0c";
export var successMessage = "custom-form-module--successMessage--6b73a";
export var textField = "custom-form-module--textField--174da";
export var title = "custom-form-module--title--9eb7e";